<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child">
          <div class="level is-mobile">
            <div class="level-left" />
            <div class="level-right">
              <div>
                <label class="typo__label">Date range</label>
                <div class="date-range-picker">
                  <date-range-picker
                    v-model="dateFilter"
                    :ranges="ranges"
                    opens="left"
                    @update="onDateChange"
                  >
                    <div
                      slot="input"
                      slot-scope="picker"
                    >
                      {{ picker.startDate | formatShortDate }} - {{ picker.endDate | formatShortDate }}
                    </div>
                  </date-range-picker>
                </div>
              </div>
            </div>
          </div>
          <apex-chart height="300" type="bar" :options="chartOptions" :series="series" />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import { apiEndpoint } from '@/utils/url-manager.js';

export default {
  name: 'FormsReports',
  components: {
    DateRangePicker,
    ApexChart,
  },

  filters: {
    formatShortDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
      }

      return undefined;
    },
  },

  data() {
    const from = moment()
      .startOf('day')
      .subtract(30, 'days');
    const to = moment().endOf('day');

    return {
      dateFilter: {
        startDate: from.toDate(),
        endDate: to.toDate(),
      },
      ranges: {
        Today: [moment().toDate(), moment().toDate()],
        'This week': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
        'Last week': [
          moment()
            .subtract(1, 'week')
            .startOf('week')
            .toDate(),
          moment()
            .subtract(1, 'week')
            .endOf('week')
            .toDate(),
        ],
        'This month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
        'Last month': [
          moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate(),
          moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate(),
        ],
        'This year': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
      },
      chartOptions: {},
      series: [],
      loader: null,
      loading: false,
    };
  },

  computed: {
    from() {
      return moment(this.dateFilter.startDate)
        .startOf('day')
        .toISOString();
    },

    to() {
      return moment(this.dateFilter.endDate)
        .endOf('day')
        .toISOString();
    },
  },

  created() {
    this.chartOptions = this.getDefaultChartOptions();
    this.getStatistics();
  },

  methods: {
    getStatistics() {
      this.$http
        .get(`${apiEndpoint}forms/submissions/statistics`, {
          params: {
            from: this.from,
            to: this.to,
          },
        })
        .then((response) => {
          const chartOptions = this.getDefaultChartOptions();
          chartOptions.xaxis.categories = response.data.categories;
          if (!response.data.series[0].name) {
            chartOptions.yaxis.max = 5;
            chartOptions.yaxis.tickAmount = 5;
          }

          this.chartOptions = chartOptions;
          this.series = response.data.series;
        });
    },

    getDefaultChartOptions() {
      return {
        colors: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#e5c494', '#b3b3b3'],
        fill: {
          opacity: 1,
        },
        chart: {
          id: 'reports-chart',
          stacked: true,
          height: 350,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 200,
            animateGradually: {
              enabled: true,
              delay: 50,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 150,
            },
          },
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: [],
          position: 'bottom',
        },
        yaxis: {
          title: {
            text: 'Submissions',
          },
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter(value, index) {
              return value.toFixed();
            },
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'left',
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
          },
        },
      };
    },

    onDateChange() {
      this.getStatistics();
    },
  },
};
</script>
